var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":"400","loading":_vm.fetching,"loading-text":"Fetching users...","footer-props":_vm.footerProps,"server-items-length":_vm.totalUsers,"options":_vm.options,"item-class":_vm.rowBackground},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-row"},[_c('v-text-field',{staticClass:"mx-4 pt-6",attrs:{"label":"Search user id","outlined":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","loading":_vm.fetching},on:{"click":_vm.resetFetch}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('div',{staticClass:"table-row search-options"},[_c('v-switch',{attrs:{"label":"Show Alts"},model:{value:(_vm.showAlts),callback:function ($$v) {_vm.showAlts=$$v},expression:"showAlts"}})],1)])]},proxy:true},{key:"item.fullUsername",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-info"},[_c('v-avatar',{attrs:{"size":"32"}},[_c('v-img',{attrs:{"src":item.avatarUrl,"alt":"avatar"}})],1),_c('div',{staticClass:"user-info-name"},[_vm._v(" "+_vm._s(item.fullUsername)+" ")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('AdminUserReportModal',{attrs:{"user":item}})],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIp(item))}})])]}}])}),(_vm.overrideUser)?_c('AdminUserReportModal',{attrs:{"user":_vm.overrideUser,"isOverride":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }